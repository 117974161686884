import * as THREE from "three";
import {useRef, useEffect, } from "react";
import {OBJLoader} from "three/addons/loaders/OBJLoader";
import {gsap} from "gsap/dist/gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

const Starlink = (props) => {
    const mount = useRef(null)
    // let scrollPercent = 0

    const buildWebsite = (container, object, camera) => {
        const firstAnimation = new gsap.timeline()
        firstAnimation.set(object.position, {x: -0.5, y: -1.1, z: 5})
        firstAnimation.set(object.rotation, {y: Math.PI/3.8})
        firstAnimation.fromTo(object.position, {x: -0.5, y: -1.1, z: 5}, {x: 1, y: -1.2, z: 0.7, scrollTrigger: {
                trigger: ".App",
                scrub: true,
                start: 0,
                end: "+="+document.getElementsByClassName('hero')[0].clientHeight
            }}, 0)
        firstAnimation.fromTo(object.rotation, {y: Math.PI/3.8}, {y: -(Math.PI)/1.9, scrollTrigger: {
                trigger: ".App",
                scrub: true,
                start: 0,
                end: "+="+document.getElementsByClassName('hero')[0].clientHeight
            }}, 0)
        firstAnimation.fromTo(object.position, {x: 1, y: -1.2, z: 0.7}, {x: -1.5, y: -2, z: -0.8, scrollTrigger: {
                trigger: ".App",
                scrub: true,
                start: document.getElementsByClassName('hero')[0].clientHeight,
                end: "+="+document.getElementsByClassName('section')[0].clientHeight
            }}, 1)
        firstAnimation.fromTo(object.rotation, {y: -(Math.PI)/1.9}, {y: 0.2, scrollTrigger: {
                trigger: ".App",
                scrub: true,
                start: document.getElementsByClassName('hero')[0].clientHeight,
                end: "+="+document.getElementsByClassName('section')[0].clientHeight
            }}, 1)
    }

    useEffect(() => {
        let width = mount.current.clientWidth
        let height = mount.current.clientHeight
        let frameId

        const scene = new THREE.Scene()

        const camera = new THREE.PerspectiveCamera(20, width / height, 1, 1000)
        // const camera = new THREE.OrthographicCamera( width / - 2, width / 2, height / 2, height / - 2, 0, 100 );
        camera.position.x = 0
        camera.position.y = 0
        camera.position.z = 11

        // const helper = new THREE.CameraHelper( camera )
        // scene.add( helper )

        const size = 10;
        const divisions = 10;

        const gridHelperX = new THREE.GridHelper( size, divisions );
        scene.add( gridHelperX );
        gridHelperX.rotation.x = Math.PI/2

        const light = new THREE.HemisphereLight( 0xffffbb, 0x080820, 1 );
        scene.add( light );

        const renderer = new THREE.WebGLRenderer({ antialias: true })

        gsap.registerPlugin(ScrollTrigger)

        const loader = new OBJLoader()
        loader.load("antenna.obj", (object) => {
            const container = new THREE.Object3D()

            container.add(object)
            container.add(camera)
            scene.add(container)

            const axesHelper = new THREE.AxesHelper( 5 );
            container.add(axesHelper)
            object.add(axesHelper)
            // camera.lookAt(new THREE.Vector3(0, 0, 0))

            buildWebsite(container, object, camera)
        })

        renderer.setClearColor('#262626')
        renderer.setSize(width, height)

        const renderScene = () => {
            renderer.render(scene, camera)
        }

        const handleResize = () => {
            width = mount.current.clientWidth
            height = mount.current.clientHeight
            renderer.setSize(width, height)
            camera.aspect = width / height
            camera.updateProjectionMatrix()
            renderScene()
        }

        const animate = () => {
            renderScene()
            // camera.position.z += 0.001
            // console.log(camera.position.z)
            frameId = window.requestAnimationFrame(animate)
        }

        const start = () => {
            if (!frameId) {
                frameId = requestAnimationFrame(animate)
            }
        }

        const stop = () => {
            cancelAnimationFrame(frameId)
            frameId = null
        }

        mount.current.appendChild(renderer.domElement)
        window.addEventListener('resize', handleResize)
        start()

        return () => {
            stop()
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return <div className="starlink" ref={mount} />
}

export default Starlink;