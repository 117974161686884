/*
* web
* - hero
* - Elem haut grand left - text right
* - Element haut bas right - text left
* - Element petit - text list dot
* - Elem on boat
* */

const WebText = () => {
    return (
        <div className="content">
            <div className="section hero">
                hero
            </div>
            <div className="section">
                Elem haut grand left - text right
            </div>
            <div className="section">
                Element haut bas right - text left
            </div>
            <div className="section">
                Element petit - text list dot
            </div>
            <div className="section">
                Elem on boat
            </div>
        </div>
    )
}

export default WebText