import './assets/scss/main.scss';
import WebText from "./components/WebText";
import Starlink from "./components/Starlink";


function App() {
  return (
    <div className="App">
      <WebText />
      <Starlink />
    </div>
  );
}

export default App;
